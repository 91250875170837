import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import SmartTable from 'components/common/tables/smartTable'
import UserLink from 'components/common/userLink'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import React from 'react'
import API from 'services/api'
import { AiEvaluationQuestionType } from 'types/ai/evaluation'
import { i18nPath } from 'utils/i18nHelpers'
import { ButtonLink } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import { useHistory } from 'react-router-dom'

const I18N = i18nPath('views.ai_evaluation.questions')

const AiEvaluationQuestionsTable = () => {
  const {
    data, isLoading, paginationData, callApi, isLoaded,
  } = useFetch(API.admin.ai.evaluation.questions.fetchAll)

  const history = useHistory()

  const columns = [
    {
      header: I18N('table_header.content'),
      col: 'col-content',
      accessor: (question: AiEvaluationQuestionType) => <span>{question.content}</span>,
    },
    {
      header: I18N('table_header.user'),
      col: 'col-user',
      style: { width: '200px' },
      accessor: (question: AiEvaluationQuestionType) => <UserLink user={question.user} />,
    },
    {
      header: I18N('table_header.company'),
      col: 'col-company',
      accessor: (question: AiEvaluationQuestionType) => <span>{question.companyName}</span>,
    },
    {
      header: I18N('table_header.expected_answer'),
      col: 'col-expected-answer',
      accessor: (question: AiEvaluationQuestionType) => <AiAnswerContent content={question.expectedAnswer} />,
    },
  ]

  const onRowClick = (question: AiEvaluationQuestionType) => {
    history.push(`/admin/ai_evaluation/questions/${question.id}/edit`)
  }

  if (!isLoaded) return <CirclesLoadingIndicator className='TableLoadingIndicator' />

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h3>{I18N('title')}</h3>
        <ButtonLink to='/admin/ai_evaluation/questions/new'>
          <CdnSvg src='/images/plusIcon.svg' className='mr-2 create-question-icon' style={{ width: '15px' }} />
          Create Question
        </ButtonLink>
      </div>

      <SmartTable
        isLoading={isLoading}
        LoadingComponent={<CirclesLoadingIndicator className='TableLoadingIndicator' />}
        columns={columns}
        data={data}
        className='white-bg-table table-with-row-hover'
        showPagination
        page={paginationData.page}
        pages={paginationData.totalPages}
        onPaginationClick={callApi}
        onSortChange={() => {}}
        sortKey='content'
        sortedAsc={false}
        onRowClick={onRowClick}
      />
    </div>
  )
}

export default AiEvaluationQuestionsTable
