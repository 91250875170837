import AiEvaluation from 'components/analytics/ai_answers/aiEvaluation'
import BackButton from 'components/common/backButton'
import ClearyCard from 'components/common/card'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import { formatFloat } from 'components/common/utils'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import AnsweredLabel from 'pages/admin/ai/answeredLabel'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import API from 'services/api'
import { AiEvaluationAnswerType } from 'types/ai/evaluation'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CollapsableItem from 'components/common/collapsableItem'

const I18N = i18nPath('views.ai_evaluation.answer')

const AiEvaluationAnswerPage = () => {
  const { answerId } = useParams()
  const {
    data, isLoaded,
  } = useFetch<AiEvaluationAnswerType>(API.admin.ai.evaluation.answers.fetch, [answerId])

  const [isAllLLMCallsCollapsed, setIsAllLLMCallsCollapsed] = useState(true)

  if (!isLoaded) return <CirclesLoadingIndicator />

  if (!data) return <div>No data</div>

  const hasEvaluation = [
    data.contextRelevanceScore,
    data.faithfulnessScore,
    data.answerRelevanceScore,
    data.ragasScore,
    data.expectedAnswerScore,
  ].some(score => typeof score === 'number')

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title', { answerId })}</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url={`/admin/ai_evaluation/${data.run.id}`} className='mb-4' />

        <div className='mb-4 d-flex gap-3'>
          <ClearyCard className='flex-grow-1'>
            <h5>Tokens</h5>
            <p>{data.totalTokens}</p>
          </ClearyCard>

          <ClearyCard className='flex-grow-1'>
            <h5>Cost</h5>
            <p>${formatFloat(data.totalCost, 6)}</p>
          </ClearyCard>
        </div>

        <ClearyCard className='mb-3'>
          <h4>{I18NCommon('question')}</h4>
          <p>{data.question.content}</p>

          <h4>{I18NCommon('answered')}</h4>
          <div className='mb-3'>
            <AnsweredLabel expectedAnswerScore={data.expectedAnswerScore} />
          </div>

          <h4>{I18NCommon('answer')}</h4>
          <div className='mb-3'>
            <AiAnswerContent content={data.answer} />
          </div>

          {data.expectedAnswer && (
            <>
              <h4>{I18NCommon('expected_answer')}</h4>
              <div className='mb-3'>
                <AiAnswerContent content={data.expectedAnswer} />
              </div>
            </>
          )}

          {data.expectedAnswerExplanation && (
            <>
              <h4>{I18NCommon('expected_answer_explanation')}</h4>
              <div className='mb-3'>
                <div dangerouslySetInnerHTML={{ __html: data.expectedAnswerExplanation.replace(/\n/g, '<br />') }} />
              </div>
            </>
          )}

          <h4>{I18NCommon('evaluation')}</h4>
          {hasEvaluation ? (
            <div className='mb-3'>
              <AiEvaluation
                contextRelevanceScore={data.contextRelevanceScore}
                faithfulnessScore={data.faithfulnessScore}
                ragasScore={data.ragasScore}
                answerRelevanceScore={data.answerRelevanceScore}
                expectedAnswerScore={data.expectedAnswerScore}
              />
            </div>
          ) : (
            <p>{I18NCommon('no_evaluation')}</p>
          )}

          <h4>{I18NCommon('context')}</h4>
          <CollapsableItem
            label={(
              <button className='btn btn-secondary'>View context</button>
            )}
            defaultIsCollapsed={true}
          >
            <pre style={{ whiteSpace: 'pre-wrap' }}>{data.context}</pre>
          </CollapsableItem>
        </ClearyCard>

        <button className='btn btn-primary mb-3' onClick={() => setIsAllLLMCallsCollapsed(value => !value)}>Toggle all LLM calls</button>

        {data.llmCalls.map((llmCall, index) => (
          <ClearyCard key={`llm-call-${index}-${isAllLLMCallsCollapsed}`} className='mb-3'>
            <CollapsableItem
              label={(
                <div className='d-flex gap-4'>
                  <div>
                    <div className='text-secondary text-small'>Model</div>
                    <div className='text-small'>{llmCall.model}</div>
                  </div>
                  <div>
                    <div className='text-secondary text-small'>Prompt tokens - cost</div>
                    <div className='text-small'>{llmCall.usage.promptTokens} - ${formatFloat(llmCall.usage.promptCost, 6)}</div>
                  </div>
                  <div>
                    <div className='text-secondary text-small'>Completion tokens - cost</div>
                    <div className='text-small'>{llmCall.usage.completionTokens} - ${formatFloat(llmCall.usage.completionCost, 6)}</div>
                  </div>
                  <div>
                    <div className='text-secondary text-small'>Total tokens - cost</div>
                    <div className='text-small'>{llmCall.usage.totalTokens} - ${formatFloat(llmCall.usage.totalCost, 6)}</div>
                  </div>
                </div>
              )}
              defaultIsCollapsed={isAllLLMCallsCollapsed}
            >

              <pre style={{ whiteSpace: 'pre-wrap' }} className='text-small'>
                {JSON.stringify(_.pick(llmCall, ['messages', 'response', 'toolCalls']), null, 2)}
              </pre>
            </CollapsableItem>
          </ClearyCard>
        ))}
      </main>
    </>
  )
}

export default AiEvaluationAnswerPage
