import React, {
  Fragment, useEffect, useMemo, useState
} from 'react'
import {
  SuggestedMessageEntryType,
  TicketEntryType,
  TicketMessageEntryType,
  TicketType,
  WorkflowRunEntryType
} from 'types/ticketing/ticket'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useCursorPagination from 'components/common/hooks/useCursorPagination'
import API from 'services/api'
import { TicketingMessageType } from 'types/ticketing/message'
import moment from 'moment'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import { SMALL_TOOLBAR_WITH_ATTACHMENTS } from 'components/common/tiptap/toolbar/toolbarVariations'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { ButtonSmallNarrow } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import useStickyScroll from 'components/ai/chat/ask_ai_modal/hooks/useStickyScroll'
import MessageEntry from './entries/messageEntry'
import WorkflowRunEntry from './entries/workflowRunEntry'
import SuggestedMessageEntry from './entries/suggestedMessageEntry'


type Props = {
  ticket: TicketType
  isAdmin: boolean
}

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

const isTicketMessage = (entry: TicketEntryType): entry is TicketMessageEntryType => entry.entryableType === 'Ticketing::Message'
const isWorkflowRun = (entry: TicketEntryType): entry is WorkflowRunEntryType => entry.entryableType === 'Workflow::Run'
const isSuggestedMessage = (entry: TicketEntryType): entry is SuggestedMessageEntryType => entry.entryableType === 'Ticketing::SuggestedMessage'

const Entry = ({ entry, reloadEntries, updateContentMessage }) => {
  if (isTicketMessage(entry)) {
    return (
      <MessageEntry
        user={entry.entryable.user}
        date={entry.createdAt}
        html={entry.entryable.content}
      />
    )
  }

  if (isWorkflowRun(entry)) {
    return (
      <WorkflowRunEntry entry={entry} />
    )
  }

  if (isSuggestedMessage(entry)) {
    return (
      <SuggestedMessageEntry
        entry={entry}
        reloadEntries={reloadEntries}
        updateContentMessage={updateContentMessage}
      />
    )
  }

  return null
}

const TicketEntries = ({ ticket, isAdmin = false }: Props) => {
  const ticketingApi = isAdmin ? API.admin.ticketing : API.ticketing

  const {
    data: entries,
    setData: setEntries,
    isLoading: isLoadingEntries,
    isLoaded: isLoadedEntries,
    onLoadMore: onLoadMoreEntries,
    hasMore: hasMoreEntries,
    resetCursor: resetEntriesCursor,
  } = useCursorPagination<TicketEntryType>(
    API.ticketing.tickets.entries.fetchAll,
    [ticket.id]
  )

  const [newMessageContent, setNewMessageContent] = useState('')
  const [editorKey, setEditorKey] = useState(0)

  const [createMessage, { isLoading: isCreatingMessage }] = useApi(ticketingApi.messages.create, {
    onSuccess: (message) => {
      addMessage(message)
      updateContentMessage('')
    },
    updateEntitySlice: true,
  })

  const addMessage = (message: TicketingMessageType) => {
    setEntries([
      ...entries,
      {
        id: message.id,
        entryableId: message.id,
        ticketId: ticket.id,
        entryableType: 'Ticketing::Message',
        entryable: message,
        createdAt: message.createdAt,
        updatedAt: message.updatedAt,
      },
    ])
  }

  const updateContentMessage = (content: string) => {
    setNewMessageContent(content)
    setEditorKey(prevKey => prevKey + 1)
  }

  const handleCreateMessage = () => {
    createMessage(ticket.id, { content: newMessageContent })
  }

  const { scrollableRef, isScrollAtTheTop } = useStickyScroll()

  useEffect(() => {
    if (isScrollAtTheTop && hasMoreEntries) {
      onLoadMoreEntries()
    }
  }, [isScrollAtTheTop, hasMoreEntries])

  return (
    <div className='TicketEntries d-flex flex-column overflow-y-hidden justify-content-between flex-grow-1'>
      <div className='d-flex flex-column gap-3 overflow-y-auto' ref={scrollableRef}>
        {!isLoadingEntries && !hasMoreEntries && (
          <MessageEntry
            user={ticket.creator}
            date={ticket.createdAt}
            html={ticket.description}
          />
        )}

        {isLoadingEntries && !isLoadedEntries && <CirclesLoadingIndicator />}

        {entries.map(entry => (
          <Entry
            key={entry.id}
            entry={entry}
            reloadEntries={() => {
              resetEntriesCursor()
              setEntries([])
              onLoadMoreEntries()
            }}
            updateContentMessage={updateContentMessage}
          />
        ))}
      </div>

      <div className='mt-5'>
        <TiptapEditor
          key={editorKey}
          className='TipTapEditor bordered'
          editorContentClassName='p-3'
          onChange={html => setNewMessageContent(html)}
          html={newMessageContent}
          configuration={{
            ...SIMPLE_EDITOR_CONFIGURATION,
            imagesEnabled: true,
            filesEnabled: true,
            userMentionsEnabled: true,
            placeholder: I18N('new_message_placeholder'),
            focusOnInitialize: true,
          }}
          toolbarItems={SMALL_TOOLBAR_WITH_ATTACHMENTS}
        />
        <ButtonSmallNarrow
          showLoadingSpinner={isCreatingMessage}
          onClick={handleCreateMessage}
          disabled={!newMessageContent || isCreatingMessage}
          className='mt-2 float-right'
        >
          {ticket.status === 'closed' ? I18N('send_and_reopen') : I18NCommon('send')}
        </ButtonSmallNarrow>
      </div>
    </div>
  )
}

export default TicketEntries
